var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"initial-investigation"},[_c('actionSelectionBox',[_c('div',{staticClass:"first-row-inputs"},[_c('div',{staticClass:"action-select"},[_c('p',{staticClass:"subtitle-2"},[_vm._v("Select an action for the following disputes")]),_c('radioGroup',{attrs:{"required":""},on:{"input":_vm.setActionForDisputes},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}},_vm._l((_vm.radioActions),function(action){return _c('radio',{key:action,attrs:{"label":action,"value":action}})}),1)],1),_c('div',[(_vm.isDenySpecific)?_c('p',{staticClass:"deny-specific-disputes-info"},[_vm._v(" Please select the disputes that will be denied. When this action is applied, the denied disputes will be removed from the list and you will have the opportunity to apply additional actions to the remaining disputes. ")]):_vm._e(),(_vm.selectedAction === 'Deny Claim' || _vm.isDenySpecific)?_c('dropdown',{attrs:{"placeholder":"Select denial reason","label":"Please select an appropriate denial reason from the following list","items":_vm.denyReasonsDropdownItems},on:{"change":_vm.updateDenyReason},model:{value:(_vm.selectedDenyReason),callback:function ($$v) {_vm.selectedDenyReason=$$v},expression:"selectedDenyReason"}}):_vm._e()],1),(_vm.justificationRequired)?_c('dropdown',{attrs:{"placeholder":`Select ${
          _vm.justificationRequiredCount === 1
            ? 'justification'
            : 'justifications'
        }`,"label":`Please select ${_vm.justificationRequiredCount} ${
          _vm.justificationRequiredCount === 1
            ? 'justification'
            : 'justifications'
        } from the following list`,"items":_vm.denyJustificationsDropdownItems,"multiple":""},on:{"change":_vm.updateDenyJustification},model:{value:(_vm.selectedJustifications),callback:function ($$v) {_vm.selectedJustifications=$$v},expression:"selectedJustifications"}}):_vm._e()],1),(_vm.selectedAction === 'Deny Claim' && _vm.isDenialCommentaryRequired)?_c('textInput',{attrs:{"multiline":"","label":"Please provide commentary to support your denial decision."},on:{"change":_vm.updateDenyCommentary}}):_vm._e()],1),_c('h6',[_vm._v("Disputes")]),_c('disputesTable',{attrs:{"claimId":_vm.claimId,"columns":_vm.tableColumns,"items":_vm.tasks,"isLoading":_vm.isLoading,"showSelect":_vm.isDenySpecific},model:{value:(_vm.selectedDisputes),callback:function ($$v) {_vm.selectedDisputes=$$v},expression:"selectedDisputes"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }