<template>
  <div class="initial-investigation">
    <actionSelectionBox>
      <div class="first-row-inputs">
        <div class="action-select">
          <p class="subtitle-2">Select an action for the following disputes</p>
          <radioGroup
            v-model="selectedAction"
            @input="setActionForDisputes"
            required
          >
            <radio
              v-for="action in radioActions"
              :key="action"
              :label="action"
              :value="action"
            />
          </radioGroup>
        </div>
        <div>
          <p v-if="isDenySpecific" class="deny-specific-disputes-info">
            Please select the disputes that will be denied. When this action is
            applied, the denied disputes will be removed from the list and you
            will have the opportunity to apply additional actions to the
            remaining disputes.
          </p>
          <dropdown
            v-if="selectedAction === 'Deny Claim' || isDenySpecific"
            v-model="selectedDenyReason"
            @change="updateDenyReason"
            placeholder="Select denial reason"
            label="Please select an appropriate denial reason from the following list"
            :items="denyReasonsDropdownItems"
          />
        </div>
        <dropdown
          v-if="justificationRequired"
          v-model="selectedJustifications"
          @change="updateDenyJustification"
          :placeholder="`Select ${
            justificationRequiredCount === 1
              ? 'justification'
              : 'justifications'
          }`"
          :label="`Please select ${justificationRequiredCount} ${
            justificationRequiredCount === 1
              ? 'justification'
              : 'justifications'
          } from the following list`"
          :items="denyJustificationsDropdownItems"
          multiple
        />
      </div>
      <textInput
        v-if="selectedAction === 'Deny Claim' && isDenialCommentaryRequired"
        multiline
        label="Please provide commentary to support your denial decision."
        @change="updateDenyCommentary"
      />
    </actionSelectionBox>
    <h6>Disputes</h6>
    <disputesTable
      v-model="selectedDisputes"
      :claimId="claimId"
      :columns="tableColumns"
      :items="tasks"
      :isLoading="isLoading"
      :showSelect="isDenySpecific"
    />
  </div>
</template>
          
<script>
import { formatAmount } from "@/util/format";

import actionSelectionBox from "./action-selection-box";
import disputesTable from "./disputes-table";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import radio from "@/qux-common/qux22/components/molecules/radio";
import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    actionSelectionBox,
    disputesTable,
    dropdown,
    radio,
    radioGroup,
    textInput,
  },
  props: {
    actions: {
      type: Array,
    },
    claimId: {
      type: String,
    },
    tasks: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAction: undefined,
      selectedDenyReason: undefined,
      selectedDisputes: [],
      selectedJustifications: [],
      tableColumns: [
        {
          text: "Dispute ID",
          value: "DisputeId",
        },
        {
          text: "Transaction Date",
          value: "Detail.TransactionDate",
        },
        {
          text: "Transaction Description",
          value: "Detail.TransactionDescription",
        },
        {
          text: "Dispute Amount",
          value: "Detail.DisputeAmount",
        },
        {
          text: "Chargeback Rights",
          value: "Detail.ChargebackRightsIndicator",
        },
        {
          align: "end",
          value: "menu",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    actionToReturn() {
      if (this.selectedAction === "Deny Specific Disputes") {
        return "Deny Claim";
      }
      return this.selectedAction;
    },
    radioActions() {
      const actions = [...this.actions];
      // need to manually add 'Deny Specific Disputes' action after 'Deny Claim'
      const index = actions.indexOf("Deny Claim");
      if (index !== -1) {
        actions.splice(index + 1, 0, "Deny Specific Disputes");
      }
      return actions;
    },
    isDenialCommentaryRequired() {
      return this.tasks[0].Detail.IsDenialCommentaryRequired;
    },
    denyReasons() {
      return this.tasks[0].Detail.DenyReasonList;
    },
    denyReasonsDropdownItems() {
      if (!this.denyReasons || !this.denyReasons.length) return [];
      return this.denyReasons.map((reason) => ({
        text: reason.Value,
        value: reason.Key,
      }));
    },
    denyJustifications() {
      return this.tasks[0].Detail.DenyJustificationList;
    },
    denyJustificationsDropdownItems() {
      if (!this.denyJustifications || !this.denyJustifications.length)
        return [];
      return this.denyJustifications.map((reason) => ({
        text: reason.Value,
        value: reason.Key,
      }));
    },
    isDenySpecific() {
      return this.selectedAction === "Deny Specific Disputes";
    },
    justificationRequired() {
      if (!this.selectedDenyReason) return false;

      const reason = this.denyReasons.find(
        (reason) => reason.Key === this.selectedDenyReason
      );

      return reason.AreJustificationsRequired || false;
    },
    justificationRequiredCount() {
      return this.tasks[0].Detail.RequiredNumberOfDenyJustifications;
    },
  },
  methods: {
    formatAmount(amount) {
      return formatAmount(amount);
    },
    async setActionForDisputes(action) {
      if (action !== "Deny Claim" && !this.isDenySpecific) {
        this.selectedDenyReason = undefined;
        this.selectedJustifications = [];
      }
      if (this.isDenySpecific) {
        this.selectedDisputes = [];
      } else {
        this.tasks.forEach(async (dispute) => {
          await this.$store.dispatch("setTaskAction", {
            action,
            TaskId: dispute.TaskId,
          });
        });
      }
    },
    updateDenyReason(value) {
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "DenyReason",
        value,
        action: this.actionToReturn,
      });
    },
    updateDenyJustification(justifications) {
      const value = justifications.map((justification) => ({
        Key: justification,
      }));
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "DenyJustificationList",
        value,
        action: this.actionToReturn,
      });
    },
    updateDenyCommentary(value) {
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "DenialCommentary",
        value,
        action: this.actionToReturn,
      });
    },
  },
  watch: {
    selectedDisputes() {
      // this happens for deny specific disputes
      this.tasks.forEach(async (dispute) => {
        const isSelected = this.selectedDisputes.find(
          (selectedDispute) => selectedDispute.TaskId === dispute.TaskId
        );
        isSelected
          ? await this.$store.dispatch("setTaskAction", {
              action: "Deny Claim",
              TaskId: dispute.TaskId,
            })
          : await this.$store.dispatch("unsetTaskAction", {
              TaskId: dispute.TaskId,
            });
      });
    },
  },
};
</script>
          
<style lang="scss" scoped>
.initial-investigation {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
.reasons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.action-select {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.action-selection {
  .first-row-inputs {
    display: flex;
    gap: 56px;
  }
  .dropdown-wrapper {
    &::v-deep {
      .v-select {
        width: 100%;
        max-width: 375px;
      }
    }
  }
}
p.deny-specific-disputes-info {
  max-width: 70ch;
  margin-bottom: 12px;
}
</style>