<template>
  <disputeDetailsWrapper
    :claimId="selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId"
  >
    <div class="tasks" :class="showBottomBar && 'task-open'">
      <task
        v-if="
          selectedClaim &&
          selectedClaim.data &&
          selectedClaim.data.ClaimId &&
          selectedClaim.viewTask
        "
        :claimId="
          selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId
        "
        :taskLabel="taskLabel"
        @closeTask="closeCorrespondence"
      />
      <div v-else>
        <h5>Tasks</h5>
        <tasksDataTable @viewTask="viewTask" />
      </div>
      <bottomBar
        v-if="showBottomBar"
        :style="`width: calc(100% - ${panelWidth}px); left: ${panelWidth}px;`"
      >
        <qvoButton text @click="closeTask">Cancel</qvoButton>
        <qvoButton @click="handleApplyAction" :disabled="isApplyDisabled"
          >Apply</qvoButton
        >
      </bottomBar>
      <denyCommentaryModal
        v-model="denyCommentaryModal.show"
        :action="denyCommentaryModal.action"
        :ClaimId="
          selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId
        "
        :detailValue="denyCommentaryModal.detailValue"
        @closeModal="closeDenyCommentaryModal"
        @save="handleApplyAction"
      />
    </div>
  </disputeDetailsWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import bottomBar from "@/components/bottom-bar";
import denyCommentaryModal from "@/components/deny-commentary-modal";
import disputeDetailsWrapper from "@/views/dispute-details";
import task from "@/components/task";
import tasksDataTable from "@/components/claim-tasks-data-table";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    bottomBar,
    denyCommentaryModal,
    disputeDetailsWrapper,
    task,
    tasksDataTable,
    qvoButton,
  },
  data() {
    return {
      denyCommentaryModal: {
        show: false,
        detailValue: "",
        action: "",
      },
    };
  },
  computed: {
    activeTaskIds() {
      const activeTasks =
        (this.taskDetailsList[this.claimId] &&
          this.taskDetailsList[this.claimId][this.taskLabel] &&
          this.taskDetailsList[this.claimId][this.taskLabel].TaskDetailList) ||
        [];
      return activeTasks.map((task) => task.TaskId);
    },
    claimId() {
      return (
        this.selectedClaim &&
        this.selectedClaim.data &&
        this.selectedClaim.data.ClaimId
      );
    },
    isApplyDisabled() {
      if (this.taskLabel === "Initial Investigation") {
        // check that all tasks have actions set
        const doAnyTasksHaveAValueSet = this.activeTaskIds.some((id) =>
          Object.prototype.hasOwnProperty.call(this.taskActions, id)
        );
        return !doAnyTasksHaveAValueSet;
      }
      return false;
    },
    isLoading() {
      return this.pendingCalls.includes("/task/list");
    },
    showBottomBar() {
      return this.selectedClaim.viewTask && !this.disputeDetailsIsShowing;
    },
    taskLabel() {
      return this.selectedClaim && this.selectedClaim.viewTask;
    },
    ...mapGetters({
      activeClientId: "getActiveClientId",
      isEmbedded: "getIsEmbedded",
      selectedClaim: "getSelectedClaim",
      panelWidth: "getTotalPanelWidth",
      pendingCalls: "getPendingCallList",
      taskActions: "getTaskActions",
      taskDetails: "getTaskDetails",
      taskDetailsList: "getTaskDetailsList",
      taskList: "getTaskList",
    }),
  },
  methods: {
    closeTask() {
      this.$store.dispatch("setOpenTask", {
        ClaimId: this.selectedClaim.data.ClaimId,
        TaskLabel: undefined,
      });
      if (this.isEmbedded) this.$router.push("/embedded/tasks");
    },
    closeDenyCommentaryModal() {
      this.denyCommentaryModal.show = false;
    },
    retrieveTaskLists(TaskLabel) {
      this.$store.dispatch("retrieveClaimTaskList", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
      this.$store.dispatch("retrieveTaskList", {
        ClientId: this.activeClientId,
        TaskLabel,
      });
    },
    retrieveTaskDetailsList({ ClaimId, TaskLabel }) {
      this.$store.dispatch("retrieveTaskDetailsList", {
        ClaimId,
        TaskLabel,
      });
    },
    retrieveTaskSummary() {
      this.$store.dispatch("retrieveTaskSummary", {
        ClientId: this.activeClientId,
      });
    },
    clearPendingTasks() {
      this.$store.dispatch("clearPendingTasks");
    },
    clearPendingIndividualTasks() {
      this.$store.dispatch("clearPendingIndividualTasks");
    },
    viewTask(item) {
      this.$store.dispatch("setOpenTask", {
        ClaimId: this.claimId,
        TaskLabel: item.TaskLabel,
      });
    },
    async handleApplyAction() {
      const tasksWithMultipleActionsToSelect = ["Execute Accounting"];
      const tasksThatDoNotRequireAllActionsToBeSet = ["Initial Investigation"];

      const hasMultipleActionsToSelect =
        tasksWithMultipleActionsToSelect.includes(this.taskLabel);
      const needsAllActionsToBeSet =
        !tasksThatDoNotRequireAllActionsToBeSet.includes(this.taskLabel);
      const doAllTasksHaveAValueSet = this.activeTaskIds.every((id) =>
        Object.prototype.hasOwnProperty.call(this.taskActions, id)
      );

      if (needsAllActionsToBeSet) {
        if (!doAllTasksHaveAValueSet) {
          this.$store.dispatch("notifications/addNotification", {
            status: "error",
            message: hasMultipleActionsToSelect
              ? "Please select an action for all items before continuing."
              : "Please select an action to be applied before continuing.",
          });
          return;
        }
      }

      // format and submit data
      const groupTasksByAction = (activeTaskIds, tasks) => {
        const result = {};

        for (const id of activeTaskIds) {
          const action = tasks[id];
          // continue only if the action exists for the id
          if (action) {
            if (!result[action]) {
              result[action] = { TaskList: [] };
            }
            result[action].TaskList.push({ TaskId: id });
          }
        }

        return result;
      };

      const groupedTasks = groupTasksByAction(
        this.activeTaskIds,
        this.taskActions
      );

      // Check for deny commentary modal display
      const declineAction = "Decline Approval";
      const needsDeclineCommentary =
        Object.keys(groupedTasks).includes(declineAction) &&
        !(
          this.taskDetails[this.claimId] &&
          this.taskDetails[this.claimId][declineAction] &&
          this.taskDetails[this.claimId][declineAction][
            "ApprovalDeniedCommentary"
          ]
        );
      if (needsDeclineCommentary) {
        this.denyCommentaryModal.show = true;
        this.denyCommentaryModal.detailValue = "ApprovalDeniedCommentary";
        this.denyCommentaryModal.action = declineAction;
        return;
      }

      let hasErrors = false;
      for (const [action, task] of Object.entries(groupedTasks)) {
        task.TaskList = task.TaskList.map((task) => ({
          TaskId: task.TaskId,
          ClaimId: this.claimId,
          TaskLabel: this.taskLabel,
        }));
        const details =
          (this.taskDetails[this.claimId] &&
            this.taskDetails[this.claimId][action]) ||
          undefined;
        await this.$store
          .dispatch("saveTaskAction", {
            Action: action,
            Detail: details,
            TaskList: task.TaskList,
            TaskLabel: this.taskLabel,
            allTasksComplete: doAllTasksHaveAValueSet,
          })
          .catch(() => {
            hasErrors = true;
          });
      }

      if (!hasErrors) {
        this.$store.dispatch("notifications/addNotification", {
          status: "success",
          message: `${this.taskLabel} has been submitted.`,
        });
        const ClaimId = this.claimId;
        const TaskLabel = this.taskLabel;

        if (doAllTasksHaveAValueSet) {
          this.closeTask();
          setTimeout(() => {
            this.clearPendingTasks();
            this.retrieveTaskLists(TaskLabel);
            this.retrieveTaskSummary();
          }, 15000);
        } else {
          setTimeout(() => {
            this.clearPendingIndividualTasks();
            this.retrieveTaskDetailsList({ClaimId, TaskLabel});
            this.retrieveTaskSummary();
          }, 15000);
        }

       
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks {
  height: 100%;
  padding-bottom: 22px;
  &.task-open {
    height: calc(100% - 74px);
  }
}

h5 {
  margin-bottom: $qvo-spacing-24;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: 10;
  transition: width 200ms;
}
</style>