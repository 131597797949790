<template>
  <div class="disputes-table">
    <div class="top-bar">
      <textInput
        class="search"
        v-model="search"
        placeholder="Search Disputes"
        prepend-inner-icon="mdi-magnify"
      />
      <dropdownButton
        v-if="actions.length && selectedDisputes.length"
        :items="actionItems"
        title="Bulk Actions"
        @selected="bulkUpdate"
      />
    </div>
    <dataTable
      v-model="selectedDisputes"
      :headers="columns"
      :items="items"
      item-key="TaskId"
      :loading="isLoading"
      :showSelect="showSelect"
      :search="search"
    >
      <template #item.DisputeId="{ item }">
        {{ `X${item.DisputeId.substring(item.DisputeId.length - 5)}` }}
      </template>
      <template #item.Detail.Amount="{ item }">
        {{ formatAmount(item.Detail.Amount) }}
      </template>
      <template #item.Detail.DisputeAmount="{ item }">
        {{ formatAmount(item.Detail.DisputeAmount) }}
      </template>
      <template #item.Detail.LossPotentialAmount="{ item }">
        {{ formatAmount(item.Detail.LossPotentialAmount) }}
      </template>
      <template #item.Detail.RecoveredAmount="{ item }">
        {{ formatAmount(item.Detail.RecoveredAmount) }}
      </template>
      <template #item.Detail.TransactionDate="{ item }">
        {{ formatDate(item.Detail.TransactionDate) }}
      </template>
      <template #item.action="{ item }">
        <div @click.stop>
          <!-- 
            There seems to be a bug in the v-select component,
            @click.stop will cause the placeholder text to not be clickable.
            Wrapping this in a div is the current workaround.
          -->
          <dropdown
            :value="taskActions[item.TaskId]"
            @change="setTaskAction({ action: $event, TaskId: item.TaskId })"
            :items="actionItems"
          />
        </div>
      </template>
      <template #item.menu="{ item }">
        <disputedTransactionsMenu :claimId="claimId" :item="item" />
      </template>
    </dataTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatAmount, formatDate } from "@/util/format";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import disputedTransactionsMenu from "@/components/disputed-transactions-menu";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import dropdownButton from "@/qux-common/qux22/components/molecules/dropdown-button";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    dataTable,
    disputedTransactionsMenu,
    dropdown,
    dropdownButton,
    textInput,
  },
  data() {
    return {
      search: "",
      selectedDisputes: this.value || [],
    };
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    claimId: {
      type: String,
    },
    columns: {
      type: Array,
    },
    items: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    actionItems() {
      return this.actions.map((action) => ({ text: action, value: action }));
    },
    ...mapGetters({
      taskActions: "getTaskActions",
    }),
  },
  methods: {
    async bulkUpdate(action) {
      this.selectedDisputes.forEach(async (dispute) => {
        await this.setTaskAction({
          action: action.text,
          TaskId: dispute.TaskId,
        });
      });
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    formatDate(date) {
      return formatDate(date);
    },
    setTaskAction({ action, TaskId }) {
      this.$store.dispatch("setTaskAction", {
        TaskId,
        action,
      });
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedDisputes = newVal;
      },
      deep: true,
    },
    selectedDisputes: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
};
</script>
          
<style lang="scss" scoped>
.disputes-table {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
.top-bar {
  display: flex;
  gap: $qvo-spacing-24;
  .search {
    width: 100%;
    max-width: 446px;
  }
}
</style>