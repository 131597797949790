<template>
  <section class="communication-data-table">
    <textInput
      v-model="search"
      class="search"
      prependInnerIcon="mdi-magnify"
      placeholder="Search"
    />
    <subHeaderFields :fields="subHeaderFields" />
    <dataTable
      :headers="headers"
      :items="isLoading ? [] : tasksForClaim"
      itemKey="TaskId"
      :loading="isLoading"
      ref="dataTable"
      :search="search"
      @click:row="viewTask"
    >
    </dataTable>
  </section>
</template>

<script>
/**
 * @fileoverview claim tasks data table component
 * Relies on vuex for mapping state variables.
 *
 * @author Matt Libiran <matt.libiran@quavo.com>
 */
import { mapGetters } from "vuex";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import subHeaderFields from "@/components/sub-header-fields";
import textInput from "@/qux-common/qux22/components/molecules/input";
import { formatAmount, formatDate } from "@/util/format";

export default {
  components: {
    dataTable,
    subHeaderFields,
    textInput,
  },
  data() {
    return {
      /**
       * Data table headers
       */
      headers: [
        {
          text: "Task Name",
          align: "start",
          value: "TaskLabel",
        },
        {
          text: "Create Date",
          align: "start",
          value: "displayDate",
        },
      ],
      search: undefined,
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/task/list");
    },
    ClaimAge() {
      return this.getClaimAge();
    },
    ClaimAmount() {
      return this.getClaimAmount();
    },
    NextRegDate() {
      return this.getNextRegDate();
    },
    NextRecoveryDate() {
      return this.getNextRecoveryDate();
    },
    subHeaderFields() {
      return [
        { label: "Next Recovery Deadline", data: this.NextRecoveryDate },
        { label: "Next Regulatory Deadline", data: this.NextRegDate },
        { label: "Age", data: this.ClaimAge && `${this.ClaimAge} days` },
        { label: "Amount", data: this.ClaimAmount },
      ];
    },
    tasksForClaim(){
      const claimId = this.selectedClaim?.data?.ClaimId;
      return claimId ? this.claimTasks[claimId] || [] : [];
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
      claimTasks: "getClaimTasks"
    }),
  },
  methods: {
    viewTask(item) {
      this.$emit("viewTask", item);
    },
    retrieveClaimTaskList() {
      if(this.selectedClaim?.data?.ClaimId){
        this.$store.dispatch("retrieveClaimTaskList", {
          ClaimId: this.selectedClaim.data.ClaimId,
        });
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    getNextRegDate() {
      var nextRegDate;
      if (
        this.selectedClaim.taskList &&
        this.selectedClaim.taskList.length > 0
      ) {
        nextRegDate = this.selectedClaim.taskList.slice().sort(function (a, b) {
          return (
            new Date(b.NextRegulatoryDateTime) -
            new Date(a.NextRegulatoryDateTime)
          );
        })[0].NextRegulatoryDateTime;
      }
      return formatDate(nextRegDate);
    },
    getNextRecoveryDate() {
      var nextRecDate;
      if (
        this.selectedClaim.taskList &&
        this.selectedClaim.taskList.length > 0
      ) {
        nextRecDate = this.selectedClaim.taskList.slice().sort(function (a, b) {
          return (
            new Date(b.NextRecoveryDateTime) - new Date(a.NextRecoveryDateTime)
          );
        })[0].NextRecoveryDateTime;
      }
      return formatDate(nextRecDate);
    },
    getClaimAge() {
      return this.selectedClaim.taskList && this.selectedClaim.taskList[0]
        ? this.selectedClaim.taskList[0].ClaimAge
        : undefined;
    },
    getClaimAmount() {
      return this.selectedClaim.taskList && this.selectedClaim.taskList[0]
        ? formatAmount(this.selectedClaim.taskList[0].ClaimAmount)
        : undefined;
    },
  },
  mounted() {
    if (!this.selectedClaim.taskList) this.retrieveClaimTaskList();
  },
  watch: {
    selectedClaim(claim) {
      if (!claim.taskList) this.retrieveClaimTaskList();
    },
  },
};
</script>

<style lang="scss" scoped>
.communication-data-table {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
  .search {
    max-width: 446px;
  }
  .v-data-table {
    &::v-deep {
      tr {
        cursor: pointer;
      }
    }
  }
}
</style>